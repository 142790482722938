<template>
  <div class="product-modal default-modal">
    <div class="default-modal__header">
      <button
        type="button"
        class="default-modal__close"
        @click="$emit('close')"
        aria-label="Закрыть модальное окно"
      >
        <RemixIconComponent category="system" name="close-fill" />
      </button>
    </div>
    <div class="product-modal__body default-modal__body">
      <IconComponent class="product-modal__loading" name="loading" v-if="loading" />
      <template v-else-if="data">
        <div class="product-modal__body-main">
          <div class="product-modal__images">
            <ProductGalleryComponent :items="data.images" :head_img="data.img" />
          </div>
          <div class="product-modal__description">
            <div class="product-modal__description-top">
              <!--            <div class="product-modal__description-feedback">-->
              <!--              <span> <RemixIconComponent category="system" name="star-fill" /> 4.7</span-->
              <!--              ><a>Посмотреть отзывы</a>-->
              <!--            </div>-->
              <div class="product-modal__description-title">
                <figure v-if="data.country && data.country.icon" v-html="data.country.icon" class="icon" />
                <h2>{{ data.title }}</h2>
              </div>
              <!--            <span class="product-modal__description-share">-->
              <!--              <span class="product-modal__description-share-percent">{{ data.share }}</span>-->
              <!--              {{ data.share_date }}-->
              <!--            </span>-->
            </div>
            <div class="product-modal__description-price">
              <span class="product-modal__description-price_main">
                {{ price.value | formatPrice }} за {{ price.step }}
                {{ data.unit_type_id === 1 ? "гр" : "шт" }}
              </span>
              <!--              <span class="product-modal__description-price_old">{{ data.price.value }} ₽</span>-->
              <span class="product-modal__description-price_new">{{ price.value | formatPrice }}</span>
            </div>
            <div class="product-modal__description-buttons">
              <ProductCountChangeComponent :data="data" isModal />
              <!--              <button class="btn btn&#45;&#45;gray">-->
              <!--                <RemixIconComponent category="health" name="heart-line" />-->
              <!--                Добавить в набор-->
              <!--              </button>-->
            </div>
          </div>
        </div>
        <div v-if="similar_products && similar_products.length" class="product-modal__body-slider">
          <SliderComponent
            class="home-page__sale-slider"
            :items="similar_products"
            v-slot="{ item, grid }"
            :slider-options="sliderSaleOptions"
            :title="'Похожие товары'"
            :route="{ name: 'home' }"
          >
            <CardComponent :data="item" :grid="grid" />
          </SliderComponent>
        </div>
        <!--        <div v-if="data.sets && data.sets.length" class="product-modal__body-slider">-->
        <!--          <SliderComponent-->
        <!--            class="home-page__sale-slider"-->
        <!--            :items="data.sets"-->
        <!--            v-slot="{ item }"-->
        <!--            :slider-options="sliderSaleOptions"-->
        <!--            :title="'Наборы с этим товаром'"-->
        <!--          >-->
        <!--            <SetBuyCardComponent :data="item" />-->
        <!--          </SliderComponent>-->
        <!--        </div>-->

        <!--        <div class="product-modal__body-tabs">-->
        <!--          <button-->
        <!--            @click="(isAbout = true), (isReviews = false)"-->
        <!--            :class="{ 'btn&#45;&#45;gray': isAbout }"-->
        <!--            class="btn btn&#45;&#45;gray&#45;&#45;outline"-->
        <!--          >-->
        <!--            О товаре-->
        <!--          </button>-->
        <!--          <button-->
        <!--            @click="(isAbout = false), (isReviews = true)"-->
        <!--            :class="{ 'btn&#45;&#45;gray': isReviews }"-->
        <!--            class="btn btn&#45;&#45;gray&#45;&#45;outline"-->
        <!--          >-->
        <!--            Отзывы (11)-->
        <!--          </button>-->
        <!--        </div>-->

        <div class="product-modal__body-tabs">
          <button
            class="btn"
            :class="{
              'product-modal__body-tabs--btn-gray': isAbout,
              'product-modal__body-tabs--btn-white': isReviews,
            }"
            @click="
              isAbout = true;
              isReviews = false;
            "
          >
            О товаре
          </button>
          <button
            class="btn"
            :class="{
              'product-modal__body-tabs--btn-gray': isReviews,
              'product-modal__body-tabs--btn-white': isAbout,
            }"
            @click="
              isAbout = false;
              isReviews = true;
            "
          >
            Отзывы ({{ data.reviews.length }})
          </button>
        </div>
        <div v-if="isAbout" class="product-modal__body-about">
          <h2 class="title">Описание</h2>
          <div class="product-modal__body-about--left">
            <span v-if="data.description">{{ data.description }}</span>
            <span v-else>Описание отсутствует</span>
          </div>
          <div class="product-modal__body-about--right">
            <div v-if="compoundAttributes.length" class="product-modal__body-about--right_top">
              <h6>Пищевая ценность на 100 г</h6>
              <div v-for="(item, i) in compoundAttributes" :key="i">
                <span>{{ item.attribute.title }}</span>
                <span>{{ item.string_value }} {{ item.attribute.title === "Ккал" ? "калл" : "гр." }}</span>
              </div>
            </div>
            <div v-if="compound" class="product-modal__body-about--right_content">
              <h2 class="title">Состав</h2>
              <span>{{ compound }}</span>
            </div>
            <div class="product-modal__body-about--right_info">
              <h2 class="title">Информация</h2>
              <ul class="product-modal__parameters">
                <li class="product-modal__parameters-item">
                  <span>Бренд</span>
                  <span v-if="data.brand">{{ data.brand.title }}</span>
                  <span v-else>Без бренда</span>
                </li>
                <li
                  v-if="data.manufacturer && data.manufacturer.title"
                  class="product-modal__parameters-item"
                >
                  <span>Производитель</span>
                  <span>{{ data.manufacturer.title }}</span>
                </li>
                <li v-if="data.country && data.country.title" class="product-modal__parameters-item">
                  <span>Страна</span>
                  <span>{{ data.country.title }}</span>
                </li>
                <li v-for="(item, i) in data.parameters" :key="i" class="product-modal__parameters-item">
                  <span>{{ item.value }}</span>
                  <span>{{ item.title }}</span>
                </li>
              </ul>
            </div>
          </div>
          <!--          <button class="btn btn&#45;&#45;main-hollow btn&#45;&#45;md">Раскрыть подробную информацию</button>-->
        </div>

        <div v-if="isReviews" class="product-modal__body-reviews">
          <div class="product-modal__body-reviews--left">
            <div class="product-modal__body-reviews--left_top">
              <StarsSmallComponent :avg-stars="data.reviews_avg_mark" />
              <span v-if="data && data.reviews_count"
                >{{ data.reviews_count }} {{ data.reviews_count | plural_word("отзыв", "отзыва", "отзывов") }}
              </span>
              <span v-else>Нет отзывов</span>
            </div>
            <ul class="product-modal__body-reviews--left_list">
              <li
                class="product-modal__body-reviews--left_list__wrapper"
                v-for="(item, index) in reviewsForStars"
                :key="index"
              >
                <StarsSmallComponent :avg-stars="item.mark" />
                <span
                  >{{ item.reviews_count }}
                  {{ item.reviews_count | plural_word("отзыв", "отзыва", "отзывов") }}</span
                >
              </li>
            </ul>
            <button
              class="product-modal__body-reviews--left_btn btn btn--md"
              aria-label="Оставить отзыв"
              @click="openProductReviewModal"
            >
              Оставить отзыв
            </button>
          </div>
          <div v-if="data.reviews && data.reviews.length" class="product-modal__body-reviews--right">
            <div v-for="(item, i) in data.reviews" :key="i" class="product-modal__body-reviews_item">
              <div class="product-modal__body-reviews_item-header">
                <div class="product-modal__description-feedback">
                  <span> <RemixIconComponent category="system" name="star-fill" /> {{ item.main_mark }}</span>
                  <p>{{ item?.client?.name }}</p>
                </div>
                <span>{{ humanDate(item.created_at) }}</span>
              </div>
              <p class="product-modal__body-reviews_item__text">
                {{ item.comment }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ProductGalleryComponent from "components/product/ProductGalleryComponent.vue";
import IconComponent from "components/IconComponent.vue";
import SliderComponent from "components/SliderComponent.vue";
// import SetBuyCardComponent from "components/SetBuyCardComponent.vue";
import PRODUCT from "@/graphql/queries/store/products_item.graphql";
import ADMIN_PRODUCT from "@/graphql/queries/admin/admin_products_item.graphql";
import ProductCountChangeComponent from "components/product/ProductCountChangeComponent.vue";
import ProductReviewModal from "components/modals/components/ProductReviewModal.vue";
import StarsSmallComponent from "components/StarsSmallComponent.vue";
import dayjs from "dayjs";

export default {
  name: "ProductModal",
  props: {
    id: Number,
    token: String,
  },
  components: {
    StarsSmallComponent,
    ProductCountChangeComponent,
    // SetBuyCardComponent,
    CardComponent: () => import("components/CardComponent.vue"),
    SliderComponent,
    IconComponent,
    ProductGalleryComponent,
    RemixIconComponent,
  },
  data() {
    return {
      loading: false,
      data: null,
      similar_products: [],
      isAbout: true,
      isReviews: false,
      sliderSaleOptions: {
        slidesPerView: 1.1,
        spaceBetween: 10,
        grid: {
          rows: 1,
        },
        breakpoints: {
          440: {
            slidesPerView: 1.2,
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 24,
          },
        },
      },
    };
  },
  async created() {
    await this.get();
  },
  methods: {
    humanDate(date) {
      require("dayjs/locale/ru");
      dayjs.locale("ru");
      return dayjs(date).format("DD MMMM YYYY");
    },
    openProductReviewModal() {
      this.$store.state._modals.push({
        component: ProductReviewModal,
        options: {
          id: this.id,
          callback: this.get,
        },
      });
    },
    async get() {
      this.loading = true;
      await this.$apollo.provider.clients[this.token ? "admin" : "store"]
        .query({
          query: this.token ? ADMIN_PRODUCT : PRODUCT,
          variables: {
            id: this.id,
          },
          context: {
            headers: {
              Authorization: "Bearer " + (this.token || this.auth_token),
            },
          },
        })
        .then(({ data }) => {
          this.data = data.products_item || data.admin_products_item;
          this.similar_products = data.similar_products?.data || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    auth_token() {
      return this.$store.state.auth_token;
    },
    reviewsForStars() {
      let items = [
        {
          mark: 5,
          reviews_count: 0,
        },
        {
          mark: 4,
          reviews_count: 0,
        },
        {
          mark: 3,
          reviews_count: 0,
        },
        {
          mark: 2,
          reviews_count: 0,
        },
        {
          mark: 1,
          reviews_count: 0,
        },
      ];

      if (this.data) {
        this.data.reviews.forEach((review) => {
          items.forEach((item) => {
            if (review.main_mark === item.mark) {
              item.reviews_count++;
            }
          });
        });
      }
      return items;
    },
    price() {
      if (this.data.prices && this.data.prices.length) {
        return this.data.prices[0];
      }
      return this.data.price || {};
    },
    compound() {
      // 100 это id атрибутов связанных с составом
      return this.data && this.data.attributes
        ? this.data.attributes
            .filter((item) => item.attribute.category_type_id === 100)
            .find((item) => item.attribute.title === "Состав")?.text_value
        : null;
    },
    compoundAttributes() {
      // 100 это id атрибутов связанных с составом
      return this.data && this.data.attributes
        ? this.data.attributes.filter(
            (item) => item.attribute.category_type_id === 100 && item.attribute.title !== "Состав"
          )
        : null;
    },
  },
};
</script>

<style lang="stylus">
.product-modal {
  display flex
  flex-direction column
  padding 32px
  background-color var(--white)
  width 100%
  max-width: 872px;
  border-radius: 32px;

  &__loading {
    width: 60px
    height: 60px
    margin auto
    +below(440px) {
      width: 60px
      height 60px
    }
  }

  &__body {
    display flex
    flex-direction column
    gap 35px
    padding 0
    +below(440px) {
      gap: 24px
    }
  }

  &__body-main {
    display grid
    grid-template-columns 1fr 1fr
    gap: 24px;
    width 100%
    +below(1024px) {
      display flex
      flex-direction column-reverse
    }
  }
  &__body-tabs {
    display grid
    grid-template-columns 1fr 1fr
    width: 100%;
    gap 24px

    &--btn-gray {
      background var(--gray-100) !important
      color var(--gray-800) !important
      border 1px solid var(--gray-100) !important
      transition var(--transition) !important
    }

    &--btn-white {
      background transparent !important
      color var(--gray-800) !important
      border: 1px solid var(--border-color-2) !important
      transition var(--transition) !important
    }

    .btn {
      padding 0
      height: 30px;
      font-seze: 0.875rem !important
      border-radius: 12px;
    }
  }

  &__body-about {
    display grid
    grid-template-columns 1fr 1fr
    column-gap 24px
    width: 100%;
    +below(1024px) {
      display flex
      flex-direction column;
    }

    h2.title {
      font-weight: 500;
      margin-bottom 16px
      grid-area: 1 / 1 / 2 / 3;
    }

    .btn {
      grid-area: 3 / 1 / 4 / 3;
      margin-top 16px
    }

    &--left {
      display flex
      flex-direction column
      grid-area: 2 / 1 / 3 / 2;
      +below(1024px) {
        margin-bottom 10px
      }

      span {
        font-weight: 400;
        font-size: 0.875em;
        line-height: 20px;
        color: var(--gray-900);
      }
    }

    &--right {
      display flex
      flex-direction column
      gap 17px

      span {
        font-weight: 400;
        font-size: 0.875em;
        line-height: 140%;
        color: var( --gray-900);
      }

      &_top {
        display flex
        flex-direction column
        background: var(--gray-100);
        border-radius: 16px;
        padding 16px
        gap 8px

        h6 {
          margin-bottom: 8px;
        }

        div {
          display flex
          justify-content space-between
        }
      }
    }
  }

  &__parameters {
    display flex
    flex-direction column
    gap: 5px

    &-item {
      display flex
      align-items flex-start
      justify-content space-between
      gap: 5px 16px
      +below(420px) {
        flex-wrap wrap
      }

      span:last-child {
        text-align: right
      }
    }
  }

  &__body-reviews--left_list {
    display flex
    flex-direction column
    gap 16px

    &__wrapper {
      display flex
      justify-content space-between
      align-items center

      div {
        display: flex;
        gap: 4px;
      }

      span {
        color: var(--blue);
        font-size: 0.750em
        font-style: normal;
        font-weight: 400;
        text-decoration-line: underline;
      }
    }

    .icon {
      width 16px
      height 16px
    }
  }

  &__body-reviews {
    display grid
    grid-template-columns 323fr 461fr
    width: 100%;
    gap 24px

    +below(620px) {
      display flex
      flex-direction column
      gap 16px
    }

    &--left {
      display flex
      flex-direction column
      gap 16px

      &_top {
        display flex
        flex-direction column
        background: var(--gray-100);
        border-radius: 16px;
        padding 16px
        align-items center
        gap 11px

        div {
          display flex
          gap 5px

          svg {
            width: 24px;
            height: 24px;

            path {
              fill var(--yelow-500)
            }
          }
        }

        span {
          font-weight: 500;
          font-size: 1em;
          line-height: 22px;
          color: var(--gray-900);
        }
      }

      &_btn.btn {
        transition var(--transition)
        background: var(--green-100);
        border: 1px solid var(--green-100);
        border-radius: 16px;
        font-weight: 400;
        font-size: 0.875em;
        line-height: 20px;
        color: var(--green-800);
      }
    }

    &--right {
      display flex
      flex-direction column
      gap 16px
      height: 354px;
      overflow scroll
    }

    .product-modal__body-reviews_item {
      display flex
      flex-direction column
      flex-shrink 0
      gap 8px
      background: var(--white);
      border: 1px solid var( --border-color-2);
      border-radius: 16px;
      padding 24px

      &-header {
        display flex
        justify-content space-between
        align-items: center;

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: var(--body_muted );
        }
      }

      &__text {
        font-weight: 400;
        font-size: 0.875em;
        line-height: 20px;
        color: var(--gray-900);
      }
    }
  }

  &__description {
    display flex
    flex-direction column
    gap 32px
    +below(440px) {
      gap: 15px
    }

    &-top {
      display flex
      flex-direction column
      gap 22px
    }

    &-feedback {
      display flex
      gap 8px
      align-items: center;

      span {
        display flex
        gap 4px
        padding 5px 8px
        background-color var(--gray-100)
        border-radius 4px
        font-weight: 600;
        font-size: 0.875em;
        line-height: 19px;
        color: var(--gray-900);

        svg {
          width: 15px;
          height: 15px;

          path {
            fill var(--yelow-500)
          }
        }
      }

      a {
        font-weight: 400;
        font-size: 0.875em;
        line-height: 140%;
        text-decoration-line: underline;
        color: var(--gray-900);
      }
    }

    &-title {
      display flex
      gap 16px
      align-items: center;

      h2 {
        font-weight: 500;
        font-size: 2em;
        line-height: 38px;
        color: var(--gray-900);
        margin 0
      }

      .icon {
        width 35px
        height: 35px
      }
    }

    &-share {
      display flex
      background-color var(--gray-100)
      border-radius: 8px;
      font-weight: 400;
      font-size: 1em;
      line-height: 22px;
      color: var(--gray-900);
      align-items: center;
      gap 8px
      width: fit-content;
      padding-right 8px

      span {
        color: var(--white);
        padding 4px
        background-color var(--red)
        border-radius: 8px;
      }
    }

    &-price {
      display flex
      flex-direction column
      gap: 16px

      &_main {
        font-weight: 400;
        font-size: 0.875em;
        line-height: 19px;
        color: #838383;
      }

      &_old {
        font-weight: 500;
        font-size: 0.875em;
        line-height: 19px;
        text-decoration-line: line-through;
        color: #BABABA;
        padding-top 16px
      }

      &_new {
        font-weight: 700;
        font-size: 1.75em;
        line-height: 38px;
        color: var(--red);
      }
    }

    &-buttons {
      display: grid;
      gap: 24px;
      grid-template-columns: 1fr 1fr;
      +below(560px) {
        grid-template-columns 1fr
      }
    }
  }

  &__body-slider {
    width: 100%;
  }

  .slider {
    height: auto;
  }
}
</style>
